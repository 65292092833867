import React, { useState } from "react";
import usaMapBackground from "../../../assets/USA_MAP2.jpg";
import "./Statemap.scss";

const Statemap = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className="statemap-container">
      <div
        className="statemap"
        style={{ backgroundImage: `url(${usaMapBackground})` }}
      >
        <div className="map-heading">
          <h1>States of Licensure</h1>
          <div className="underline"></div>
        </div>
        <div className="map-overlay">
          <button className="modal-button" onClick={toggleModal}>
            States of Licensure - Full List Here
          </button>
          {modalOpen && (
            <div className="modal-overlay" onClick={toggleModal}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button className="close-button" onClick={toggleModal}>
                  &times;
                </button>
                <h2>USA States</h2>
                <ul className="states-list">
                  <li>Alabama</li>
                  <li>Alaska</li>
                  <li>Arizona</li>
                  <li>Arkansas</li>
                  <li>California</li>
                  <li>Colorado</li>
                  <li>Connecticut</li>
                  <li>Delaware</li>
                  <li>Florida</li>
                  <li>Georgia</li>
                  <li>Hawaii</li>
                  <li>Idaho</li>
                  <li>Illinois</li>
                  <li>Indiana</li>
                  <li>Iowa</li>
                  <li>Kansas</li>
                  <li>Kentucky</li>
                  <li>Louisiana</li>
                  <li>Maine</li>
                  <li>Maryland</li>
                  <li>Massachusetts</li>
                  <li>Michigan</li>
                  <li>Minnesota</li>
                  <li>Mississippi</li>
                  <li>Missouri</li>
                  <li>Montana</li>
                  <li>Nebraska</li>
                  <li>Nevada</li>
                  <li>New Hampshire</li>
                  <li>New Jersey</li>
                  <li>New Mexico</li>
                  <li>New York</li>
                  <li>North Carolina</li>
                  <li>North Dakota</li>
                  <li>Ohio</li>
                  <li>Oklahoma</li>
                  <li>Oregon</li>
                  <li>Pennsylvania</li>
                  <li>Rhode Island</li>
                  <li>South Carolina</li>
                  <li>South Dakota</li>
                  <li>Tennessee</li>
                  <li>Texas</li>
                  <li>Utah</li>
                  <li>Vermont</li>
                  <li>Virginia</li>
                  <li>Washington</li>
                  <li>West Virginia</li>
                  <li>Wisconsin</li>
                  <li>Wyoming</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statemap;
