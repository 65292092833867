import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import banner from "../../assets/banner_img1.jpg";
import "./JobDetails.scss";

const JobDetails = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    coverLetter: "",
    cv: null,
  });
  const formRef = useRef(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/jobs/${jobId}`
        );
        const data = await response.json();
        setJob(data);
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "cv") {
      setFormData({ ...formData, cv: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("email", formData.email);
    formDataObj.append("phone", formData.phone);
    formDataObj.append("coverLetter", formData.coverLetter);
    formDataObj.append("cv", formData.cv);
    formDataObj.append("jobId", jobId);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/applications/send-email`,
        {
          method: "POST",
          body: formDataObj,
        }
      );

      if (response.ok) {
        alert("Application submitted successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          coverLetter: "",
          cv: null,
        });
      } else {
        alert("Failed to submit application.");
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const renderTextWithNewLines = (text) => {
    if (!text) return null;

    return text.split(".").map((sentence, index) => (
      <React.Fragment key={index}>
        {sentence.trim()}
        {sentence && "."}
        <br />
      </React.Fragment>
    ));
  };

  if (!job) {
    return <div>Loading...</div>;
  }

  return (
    <div className="job-details-page">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Career</div>
      </div>
      <div className="job-details-container">
        <div className="job-details">
          <h1 className="job-title">{job.title}</h1>
          <div>
            <strong>Description:</strong>
            <p>{job.description}</p>
          </div>
          <div>
            <strong>Duties:</strong>
            <p>{renderTextWithNewLines(job.duties)}</p>
          </div>
          <div>
            <strong>Qualifications:</strong>
            <p>{renderTextWithNewLines(job.qualifications)}</p>
          </div>
          <div>
            <strong>Preferred Skills:</strong>
            <p>{renderTextWithNewLines(job.preferredSkills)}</p>
          </div>
          <div>
            <strong>Work Schedule:</strong>
            <p>{job.workSchedule}</p>
          </div>
          <div>
            <strong>Location:</strong>
            <p>{job.location}</p>
          </div>
          <div>
            <strong>Compensation and Benefits:</strong>
            <p>{job.compensationAndBenefits}</p>
          </div>
          <p>
            <strong>Expected Salary:</strong> ${job.expectedSalary}
          </p>
        </div>

        <div className="application-form" ref={formRef}>
          <h2 className="form-title">Apply for this Job</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Your Phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <textarea
              name="coverLetter"
              placeholder="Cover Letter"
              value={formData.coverLetter}
              onChange={handleChange}
              required
            ></textarea>
            <input
              type="file"
              name="cv"
              accept=".pdf"
              onChange={handleChange}
              required
            />
            <button type="submit">Submit Application</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
