import React from "react";
import "./CTA.scss";
import license from "../../../assets/license.svg";
import time from "../../../assets/time.svg";
import projects from "../../../assets/projects.svg";
import experience from "../../../assets/projects.svg";
import NMSDC from "../../../assets/NMSDCF.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "../Home.scss";

const CTA = () => {
  const ctaItems = [
    { text: "LICENSED IN 50 STATES", icon: license },
    { text: "OVER 1000 PROJECTS COMPLETED", icon: projects },
    {
      text: "OVER 35 PLUS YEARS OF COMBINED STRUCTURAL DESIGN EXPERIENCE",
      icon: experience,
    },
    { text: "FAST TURNAROUND TIMES", icon: time },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren",
      },
    },
  };

  const itemVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        x: { type: "spring", stiffness: 100 },
        opacity: { duration: 0.2 },
      },
    },
  };

  const scaleInVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <div>
      <motion.div
        className="cta-container"
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <div>
          <motion.p className="shared-text-style" variants={itemVariants}>
            "Together, we are not just building a company; we are cultivating a
            legacy of excellence, integrity, and success."
          </motion.p>
          <motion.p className="shared-text-style" variants={itemVariants}>
            - Dinesh R. Panta, P.E., Co-Founder & President
          </motion.p>
        </div>
        <motion.div className="cta-statements">
          {ctaItems.map((item, index) => (
            <motion.div
              className="cta-statement"
              key={index}
              variants={itemVariants}
            >
              <img src={item.icon} alt="" className="cta-icon" />
              <p>{item.text}</p>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
      <motion.div
        className="nmsdc-container"
        variants={scaleInVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
      >
        <img src={NMSDC} alt="" className="nmsdc-logo" />
        <p className="nmsdc-text">MBE Certified</p>
      </motion.div>
    </div>
  );
};

export default CTA;
