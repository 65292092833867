import React, { useEffect } from "react";
import "./Contact.scss";
import banner from "../../assets/banner_img1.jpg";
import {
  faEnvelope,
  faPhone,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import office from "../../assets/office.webp";

const Contact = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name: event.target[0].value,
      email: event.target[1].value,
      phone: event.target[2].value,
      subject: event.target[3].value,
      message: event.target[4].value,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/mail/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Email sent successfully!");
      } else {
        alert("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Contact Us</div>
      </div>

      <div className="contact-content">
        <div className="contact-info-section">
          <div className="contact-info-box">
            <div className="contact-map">
              <iframe
                src="https://maps.google.com/maps?q=3333%20S%20Bannock%20St.%20Suite%20425%20Englewood%2C%20CO%2080110&t=&z=13&ie=UTF8&iwloc=&output=embed"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="contact-form-section">
          <h2>Get In Touch</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input type="text" placeholder="Name" />
              <input type="email" placeholder="Email" />
            </div>
            <div className="form-row">
              <input type="text" placeholder="Phone" />
            </div>
            <div className="form-row">
              <input type="text" placeholder="Subject" />
            </div>
            <div className="form-row">
              <textarea placeholder="Message"></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>

      <div className="contact-info">
        <div className="contact-item">
          <FontAwesomeIcon icon={faPhone} className="icon-contact" />
          <h3>Phone</h3>
          <p>
            Call us today to discuss your project needs, and let's create
            something extraordinary together.
          </p>
          <p>(720) 918-6125</p>
        </div>
        <div className="contact-item">
          <FontAwesomeIcon icon={faEnvelope} className="icon-contact" />
          <h3>Email</h3>
          <p>
            Got questions or need more information? Drop us an email, and we'll
            get back to you promptly with the answers you need.
          </p>
          <p>info@dekatengineering.com</p>
        </div>
        <div className="contact-item">
          <FontAwesomeIcon icon={faLocation} className="icon-contact" />
          <h3>Location</h3>
          <p>
            Schedule a visit to our office for a comprehensive consultation.
          </p>
          <p>3333 S Bannock St. Suite 425, Englewood, CO 80110</p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
