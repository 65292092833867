import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Team.scss";
import img1 from "../../assets/dinesh-team3.jpeg";
import img2 from "../../assets/dekat-kyle-ceo.jpg";
import img3 from "../../assets/dekat-mike.png";
import banner from "../../assets/banner_img1.jpg";
import img4 from "../../assets/dekat-logo.jpg";
import linkedinLogo from "../../assets/linkedin.png";

// Founding members array
const founders = [
  {
    id: 1,
    name: "Dinesh Raj Panta, P.E.",
    designation: "President and Co-Founder, Structural Engineer",
    quote: "Safety isn't just a priority; it's our promise.",
    image: img1,
    linkedin: "www.linkedin.com/in/dineshrpanta",
  },
  {
    id: 2,
    name: "Kyle O'Hearn, P.E.",
    designation: "CEO and Co-Founder, Structural Engineer",
    quote: "Dedicated to innovation in engineering.",
    image: img2,
    linkedin: "www.linkedin.com/in/kyle-o-hearn-p-e-53174a11b/",
  },
  {
    id: 3,
    name: "Mike Majer, P.E., S.E.",
    designation: "Sr. Structural Engineer",
    quote: "Engineering is an art and a science.",
    image: img3,
    linkedin: "www.linkedin.com/in/michael-j-majer-pe-se-747a1b15/",
  },
  {
    id: 4,
    name: "Michael Leeper, P.E.",
    designation: "Sr. Structural Engineer",
    quote: "Engineering is an art and a science.",
    image: img4,
    linkedin: "www.linkedin.com/in/michael-leeper-933b91b8/",
  },
];

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  // Function to handle opening modal and setting selected member
  const openModal = (member) => {
    setSelectedMember(member);
  };

  // Function to handle closing modal
  const closeModal = () => {
    setSelectedMember(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="team-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Team</div>
      </div>
      <h1 className="team-heading">Meet Our Team</h1>
      {/* <p className="team-description">
        Our Passion is engineering and our vision is to stand out as one of the
        best international engineering firms in the world.
      </p> */}
      <div className="founders">
        {founders.map((founder) => (
          <div
            key={founder.id}
            className="team-member founder"
            onClick={() => openModal(founder)}
          >
            <img src={founder.image} alt={founder.name} />
            <div className="team-member-info">
              <h3>{founder.name}</h3>
              <p>{founder.designation}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="founders">
        {otherTeamMembers.map((member) => (
          <div
            key={member.id}
            className="team-member founder"
            onClick={() => openModal(member)}
          >
            <img src={member.image} alt={member.name} />
            <div className="team-member-info">
              <h3>{member.name}</h3>
              <p>{member.designation}</p>
            </div>
          </div>
        ))}
      </div> */}

      {selectedMember && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <img
              src={selectedMember.image}
              alt={selectedMember.name}
              className="modal-image"
            />
            <div className="modal-info">
              <h3>{selectedMember.name}</h3>
              <p>{selectedMember.designation}</p>
              <blockquote>{selectedMember.quote}</blockquote>
              <div className="modal-social-links">
                {/* <a
                  href={selectedMember.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookLogo} alt="Facebook" />
                </a>
                <a
                  href={selectedMember.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtubeLogo} alt="YouTube" />
                </a> */}
                <a
                  href={`https://${selectedMember.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedinLogo} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="work-with-us-section">
        <h2>Work With Us</h2>
        <h3>Become a part of our team</h3>
        <p>
          Here at DEKAT Engineering, we have built a team of experts who are
          committed to their craft. We believe in working together as a
          committed community to get results for our clients.
        </p>
        <Link to="/career" className="careers-button">
          Careers
        </Link>
      </div>
    </div>
  );
};

export default Team;
